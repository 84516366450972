import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import logo from "../../static/images/logo.svg"
import { CSSTransition } from "react-transition-group"
import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"
import LanguageMenu from "./language"
import { Helmet } from "react-helmet"
if (typeof window === "undefined") {
  global.window = {}
}
const Header = ({ t: trans, intl, siteTitle }) => {
  const [headerClassName] = useState("topnav")
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [showTopNav, setShowTopNav] = useState(false)

  function onIconClick() {
    setShowTopNav(!showTopNav)
  }

  useEffect(() => {
    function resize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])
  const isMobileScreen = screenWidth <= 425

  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/7364044.js"
        />
      </Helmet>

      <header className={headerClassName}>
        <div className={"container"}>
          <div className={"top-menu"}>
            <div className={"logo"}>
              <Link to="/" title={"Modeliver"}>
                <img alt={"Logo"} src={logo} />
              </Link>
            </div>
            {!isMobileScreen ? (
              <React.Fragment>
                <div className="products">
                  <Link to={"/business"} title={"Business"}>
                    <div className="product">
                      {t({ intl: trans, id: "headerBusiness" })}
                    </div>
                  </Link>
                </div>
                <div className="products">
                  <Link to="/drive" title={"Drive"}>
                    <div className="product">
                      {t({ intl: trans, id: "headerDrive" })}
                    </div>
                  </Link>
                </div>
                <LanguageMenu />
              </React.Fragment>
            ) : null}
          </div>
          {isMobileScreen ? (
            <div className="icon" onClick={onIconClick}>
              <FontAwesomeIcon icon={faBars} size={"lg"} color="#ff6600" />
            </div>
          ) : null}
        </div>
        {isMobileScreen && showTopNav ? (
          <CSSTransition
            in={showTopNav}
            timeout={300}
            classNames="mobileNav"
            unmountOnExit
          >
            <div className="mobileTopNav">
              <div>
                <Link to={"/business"} title={"Business"}>
                  <div className="product">
                    {t({ intl: trans, id: "headerBusiness" })}
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/drive" title={"Drive"}>
                  <div className="product">
                    {t({ intl: trans, id: "headerDrive" })}
                  </div>
                </Link>
              </div>
              <LanguageMenu />
            </div>
          </CSSTransition>
        ) : null}
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTrans(Header)
