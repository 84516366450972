import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()

  const [values, setValues] = useState({
    language: "en",
  })

  function handleChange(lang) {
    i18n.changeLanguage(lang)
    setValues(lang)
  }

  return (
    <div
      style={{
        fontSize: 14,
        position: "absolute",
        right: "20%",
        top: "5%",
        display: "inline-block",
        width: "50px",
      }}
    >
      <div
        style={
          values === "fi"
            ? {
                paddingRight: 15,
                color: "#f60",
                float: "left",
                cursor: "pointer",
              }
            : { paddingRight: 15, float: "left", cursor: "pointer" }
        }
        onClick={() => handleChange("fi")}
      >
        Fi
      </div>
      <div
        style={
          values === "en"
            ? { color: "#f60", cursor: "pointer" }
            : { cursor: "pointer" }
        }
        onClick={() => handleChange("en")}
      >
        En
      </div>
    </div>
  )
}

export default LanguageMenu
