/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "../../static/styles/main.scss"
import logo from "../../static/images/logo.svg"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"
import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"
const Layout = ({ t: trans, intl, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-5"}>
                <div className={"widget__item"}>
                  <div className={"logo"}>
                    <Link to="/" title={"Modeliver"}>
                      <img width="200" height="80" alt={"Logo"} src={logo} />
                    </Link>
                  </div>

                  <div className={"about"}>
                    <p>{t({ intl: trans, id: "headerMantra" })}</p>
                  </div>
                </div>
              </div>

              <div className={"col-3"}>
                <div className={"widget__item"}>
                  <ul className={"links"}>
                    <h4>{t({ intl: trans, id: "headerProducts" })}</h4>
                    <ul>
                      <li>
                        <Link
                          to="/business"
                          title={t({ intl: trans, id: "headerBusiness" })}
                        >
                          {t({ intl: trans, id: "headerBusiness" })}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/drive"
                          title={t({ intl: trans, id: "headerDrive" })}
                        >
                          {t({ intl: trans, id: "headerDrive" })}
                        </Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div className={"col-2"}>
                <div className={"widget__item"}>
                  <div className={"links"}>
                    <h4>{t({ intl: trans, id: "headerSupport" })}</h4>
                    <ul>
                      <li>
                        <Link
                          to="/about"
                          title={t({ intl: trans, id: "headerAboutUs" })}
                        >
                          {t({ intl: trans, id: "headerAboutUs" })}
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" title={"FAQ"}>
                          {t({ intl: trans, id: "headerFAQ" })}
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://www.dropbox.com/s/wphlaq0n1r7nwj9/GDPR%20%28suomeksi%29.pdf?dl=0"
                          target="_blank"
                          title={t({ intl: trans, id: "headerPrivacy" })}
                        >
                          {t({ intl: trans, id: "headerPrivacy" })}
                        </a>
                      </li>
                      <li>
                        <Link
                          to="/terms"
                          title={t({ intl: trans, id: "headerTermsOfUse" })}
                        >
                          {t({ intl: trans, id: "headerTermsOfUse" })}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          title={t({ intl: trans, id: "headerContactUs" })}
                        >
                          {t({ intl: trans, id: "headerContactUs" })}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={"col-2"}>
                <div className={"widget__item"}>
                  <div className={"social"}>
                    <a
                      href="https://www.linkedin.com/company/modeliver"
                      target={"_blank"}
                      title={"Modeliver"}
                    >
                      <img alt={"LinkedIn"} src={iconLinkedin} />
                    </a>
                    <a
                      href="https://www.instagram.com/modeliverofficial"
                      target={"_blank"}
                      title={"Modeliver"}
                    >
                      <img alt={"Instagram"} src={iconInstagram} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={"copyright"}>
              <p>
                Copyright {new Date().getFullYear()}, {` `}{" "}
                <a
                  href="https://modeliver.com"
                  title={"Modeliver Oy (Y-tunnus: 3127522-6)"}
                >
                  Modeliver Oy (Y-tunnus: 3127522-6)
                </a>
                . {t({ intl: trans, id: "textAllRightsReserved" })}
              </p>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
